@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";

html {
	background: $black;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-family: $font-primary;
	font-size: 16px;
	line-height: 1;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $white;
}

a {
	color: $white;

	@include hover-focus {
		color: $white;
	}
}

.split{
	display: flex;
	flex-direction: column;
	@include tablet-up{
		flex-direction: row;
	}
}

.desktop{
	@include tablet-down{
		display: none !important;
	}
}

.mobile{
	@include tablet-up{
		display: none !important;
	}
}

h1,h2,h3,h4,ul,p{
	margin: 0;
}

h1,h2,h3,h4{
	font-family: $font-secondary;
}

.heading{
	font-family: $font-secondary;
	font-style: italic;
	letter-spacing: -4px;
	font-size: 4.1rem;
	text-transform: uppercase;
	font-weight: 800;
	margin-bottom: 3.8rem;
}

.btn{
	display: inline-block;
	border: solid 1px transparent;
	font-size: 2rem;
	background-color: transparent;
	border-radius: 30px;
	padding: 5px 20px;
	@include hover-focus{
		opacity: .4;
	}
	&.btn-black{
		color: $black;
		border-color: $black;
	}
	&.btn-white{
		color: $white;
		border-color: $white;
	}
	&.btn-small{
		font-size: 1.5rem;
	}
}

.bg-texture{
	position: relative;
	color: $black;
	background-color: #fff4eb;
	a{
		color: $black;
	}
	&::before{
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: url('../../dist/img/bg-texture.jpg');
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		z-index: -1;
	}
}

.bg-black{
	background-color: #16110E;
	color: $white;
}

header {
	padding: 4rem 0;
	.header-navigation{
		padding-bottom: 4rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		a{
			font-size: 2rem;
			text-transform: uppercase;
			flex: 1;
			text-align: center;
			@include hover-focus{
				opacity: .4;
			}
		}
		.logo{
			width: 100%;
			order: 1;
			@include tablet-up{
				flex: 3;
				max-width: 450px;
				order: unset;
			}
		}
	}

	.featured-release{
		text-transform: uppercase;
		text-align: center;
		a{
			display: inline-block;
			max-width: max-content;
			margin-bottom: 30px;
			img{
				width: 100%;
				max-width: 652px;
			}
		}
		h3{
			font-size: 3.2rem;
			font-weight: 500;
			letter-spacing: 2.6px;
		}
		p{
			font-size: 3rem;
			margin-bottom: 20px;
			letter-spacing: 2.1px;
		}
		.btn{
			margin-bottom: 0;
		}
	}
	overflow: hidden;
}

main {

	#music{
		padding: 60px 0;
		.container{
			text-align: center;
			.music-item{
				img{
					width: 100%;
					aspect-ratio: 1/1;
					object-fit: cover;
				}
				p{
					font-size: 2rem;
					text-transform: uppercase;	
					margin: 15px 0;
				}
			}
			.swiper-navigation{
				margin-top: 5rem;
				display: flex;
				justify-content: center;
				gap: 30px;
				.swiper-button-prev,
				.swiper-button-next{
					position: unset;					
					display: block;
					width: unset;
					height: unset;
					margin: 0;
					img{
						width: 80px;
						height: 80px;
						transition: all .4s;
					}
					&::after{
						display: none;
					}
					@include hover-focus{
						img{
							opacity: .4;
						}
					}
				}
			}
		}
	}

	#video{
		padding: 60px 0;
		.container{
			text-align: center;
		}
	}
	#tour{
		padding: 60px 0;
		.container{
			.heading{ 
				text-align: center;
			}
			.bit-widget-container{
				.bit-widget{
					background-color: transparent;
					.bit-follow-section-wrapper,
					.bit-nav-bar-container,
					.bit-play-my-city-wrapper{
						display: none;
					}
					.bit-event-lists{
						.bit-upcoming-events{
							margin: 0;
							border-bottom-color: hsla(26, 49%, 9%, 0.46);
							.bit-event{
								font-size: 1.6rem;
								border-top-color: hsla(26, 49%, 9%, 0.46);
								padding: 20px 0;
								&:first-child{
									border-top-color: transparent;
								}
								.bit-desktop-social-share-button{
									display: none;
								}
								.bit-button{
									display: inline-block;
									border: solid 1px transparent;
									font-size: 2rem;
									background-color: transparent;
									border-radius: 30px;
									padding: 5px 20px;
									color: $black;
									text-align: center;
									border-color: $black;
									font-size: 1.5rem;
									@include hover-focus{
										opacity: .4;
									}
									.bit-rsvp-text{
										width: 100%;
									}
								}
								.bit-date{
									font-family: $font-secondary;
									text-transform: uppercase;
									font-style: italic;
									font-size: 1.8rem;
								}
								.bit-venue,
								.bit-location-under-tablet{
									line-height: 1.4;
									width: 100%;
									text-transform: capitalize;
								}
							}
						}
					}
				}
			}
		}
	}

	#shop{
		padding: 60px 0;
		background-color: #FFF4EB;
		color: $black;
		.container{
			text-align: center;
			img{
				width: 100%;
				margin-bottom: 40px;
			}
			.btn{
				display: block;
				padding: 15px;
				text-transform: uppercase;
				border-radius: 50px;
			}
		}
	}

	#about{
		padding: 60px 0;
		.container{
			.split{
				gap: 40px;
				align-items: center;
				.side{
					flex: 1;
					img{
						width: 100%;
					}
					.heading{
						margin-bottom: 20px;
					}
					p{
						font-size: 1.5rem;
						line-height: 1.3;
					}
				}
			}
		}
	}

	#newsletter{
		padding: 60px 0;
		background-color: #FFF4EB;
		color: $black;
		text-align: center;
		img{
			display: inline-block;
		}
		.social{
			display: flex;
			justify-content: center;
			gap: 10px;
			margin: 60px 0;
			a{
				width: 50px;
				height: 50px;
				border-radius: 50px;
				border: solid 1px $black;
				font-size: 24px;
				display: flex;
				justify-content: center;
				align-items: center;
				color: $black;
				@include hover-focus{
					opacity: .4;
				}
			}
		}
		.split{
			.side{
				flex: 1;
				h3{
					text-transform: uppercase;
					font-style: italic;
					font-family: $font-secondary;
					font-size: 2rem;
					margin-bottom: 20px;
				}
				form{
					overflow: hidden;
					display: flex;
					flex-wrap: wrap;
					gap: 10px;
					.form-group{
						width: 100%;
						max-width: calc(50% - 10px);
					}
					label{
						position: absolute;
						left: -999999px;
						opacity: 0;
						pointer-events: none;
					}
					input,
					select{
						width: 100%;
						background-color: transparent;
						color: $black;
						font-size: 1.2rem;
						border: solid 1px $black;
						padding: 5px 10px;
						&::placeholder{
							color: $black;
						}
					}
					.hidden,
					.display-none{
						display: none;
					}
					.btn{
						display: block;
						width: 100%;
					}
				}
				@include tablet-up{
					padding: 0 40px;
					&:first-child{
						border-right: solid 1px $black;
					}
				}
				.form-alert{
					text-align: center;
					font-size: 1.2rem;
					display: none;
					&.error{
						margin-top: 10px;
					}
				}
			}
		}
	}

}

footer {
	padding: 40px 0;
	text-align: center;
	font-size: 1.6rem;
	p{
		margin-bottom: 10px;
		font-weight: 700;
	}
	.copyright{
		display: none;
	}
}